export { default as BestValuePricing } from './best_value_pricing.svg';
export { default as BgRange } from './bg-range.svg';
export { default as CheckedPrimary } from './check-filled.svg';
export { default as DiscordIconHeader } from './discord-icon-head.svg';
export { default as DotIsSelect } from './dot-isSelect.svg';
export { default as FarmingAiriIcon } from './farming-airi.svg';
export { default as FarmingMetamask } from './farming-metamask.svg';
export { default as IconCheckFilledGreen } from './icon_check_filled_green.svg';
export { default as IconCloseButton } from './icon_close_button.svg';
export { default as IconFilterNsfw } from './icon_filter_nsfw.svg';
export { default as IconFireGenCampaign } from './icon_fire_gen_campaign.svg';
export { default as IconImageFile } from './icon_image_file.svg';
export { default as IconInfoAigCampaign } from './icon_info_aig_campaign.svg';
export { default as IconOnlyLogoAiRight } from './icon_only_Logo_aiRight.svg';
export { default as IconProPricing } from './icon_pro_pricing.svg';
export { default as IconPromaxPricing } from './icon_promax_pricing.svg';
export { default as IconStartedPricing } from './icon_started_pricing.svg';
export { default as IconTooltipArrow } from './icon_tooltip_arrow.svg';
export { default as IconWarningYellow } from './icon_warning_yellow.svg';
export { default as IconAiSuggest } from './icon-ai-suggest.svg';
export { default as IconAiSuggestBlack } from './icon-ai-suggest-black.svg';
export { default as IconAIG20px } from './icon-AIG-20px.svg';
export { default as IconAIGDailyCheck } from './icon-AIG-daily-check.svg';
export { default as AigHeaderIcon } from './icon-AIG-header.svg';
export { default as IconAigHeaderGray } from './icon-aig-header-gray.svg';
export { default as IconAIGRefer } from './icon-AIG-refer.svg';
export { default as IconAiriToken } from './icon-airi-token.svg';
export { default as IconApproveMarket } from './Icon-Approve.svg';
export { default as IconArrowDown } from './icon-arrow-down.svg';
export { default as IconLeft } from './icon-arrow-left.svg';
export { default as IconLeftWhite } from './icon-arrow-left-white.svg';
export { default as IconRight } from './icon-arrow-right.svg';
export { default as IconArrowRightGray } from './icon-arrow-right-gray.svg';
export { default as IconArrowRightWhite } from './icon-arrow-right-white.svg';
export { default as ArrowRight2020Icon } from './icon-arrow-right20*20.svg';
export { default as ArtworkLeaderboard } from './icon-artwork-leaderboard.svg';
export { default as IconBackBlack } from './icon-back-black.svg';
export { default as BlingAigHeaderIcon } from './icon-bling-AIG-header.svg';
export { default as IconBlogFooter } from './icon-blog-footer.svg';
export { default as CalendarIcon } from './icon-calendar.svg';
export { default as Calendar2020Icon } from './icon-calendar_20*20.svg';
export { default as CancelFileIcon } from './icon-cancel-file.svg';
export { default as IconCardTemplateEye } from './icon-card-template-eye.svg';
export { default as IconCheckAttendance } from './icon-check-attendance.svg';
export { default as IconCheckChip } from './icon-check-chip.svg';
export { default as IconCheckPricing } from './icon-check-pricing.svg';
export { default as IconCheckWhite } from './icon-check-white.svg';
export { default as IconCheckboxGen } from './icon-checkbox-gen.svg';
export { default as IconCheckboxNull } from './icon-checkbox-null.svg';
export { default as IconClose } from './icon-close.svg';
export { default as IconCloseChip } from './icon-close-chip.svg';
export { default as IconCloseGray } from './icon-close-gray.svg';
export { default as IconCloseGray2828 } from './icon-close-gray-28*28.svg';
export { default as CloseLineHeaderIcon } from './icon-close-line-header.svg';
export { default as IconCopy } from './icon-copy.svg';
export { default as IconCopySecond } from './icon-copy-2.svg';
export { default as CopyLinkIcon } from './Icon-copy-link.svg';
export { default as IconCopyRefer } from './icon-copy-refer.svg';
export { default as IconCreateAiLinear } from './icon-create-ai-linear.svg';
export { default as IconCreateAiPrimaryLinear32 } from './icon-create-ai-primary-linear-32*32.svg';
export { default as CreateArtworkIcon } from './icon-create-artwork.svg';
export { default as IconCreditFlashGray } from './icon-credit-flash-gray.svg';
export { default as IconCreditFlashOrange } from './icon-credit-flash-orange.svg';
export { default as IconCrown } from './icon-crown.svg';
export { default as CrownCardTrendingIcon } from './icon-crown-card-trending.svg';
export { default as DeleteIconBlack } from './icon-delete-black.svg';
export { default as IconDeleteWhite } from './icon-delete-white.svg';
export { default as DiceIcon } from './icon-dice.svg';
export { default as IconDices } from './icon-dices.svg';
export { default as IconDices16px } from './icon-dices-16px.svg';
export { default as DirectionIcon } from './icon-direction.svg';
export { default as DirectorCardCreatorIcon } from './icon-director-card-creator.svg';
export { default as DiscordIcon } from './icon-discord.svg';
export { default as IconDiscordLinear32 } from './icon-discord-linear32-32.svg';
export { default as IconDiscordPrimaryLinear32 } from './icon-discord-primary-linear-32*32.svg';
export { default as IconDiscordWhite } from './icon-discord-white.svg';
export { default as IconDiscussSupport } from './icon-discuss-support.svg';
export { default as IconDotFtCampaign } from './icon-dot-ft-campaign.svg';
export { default as IconDotModel } from './icon-dot-model-detail.svg';
export { default as IconDownloadWhite } from './icon-dowload-white.svg';
export { default as DownDirectionIcon } from './icon-down-direction.svg';
export { default as IconDownSolid } from './icon-down-solid.svg';
export { default as IconDownloadImage } from './icon-download-image.svg';
export { default as IconDownloadModel } from './icon-download-model.svg';
export { default as EarnAigProfileIcon } from './icon-earn-aig-profile.svg';
export { default as IconEditBlack } from './icon-edit-black.svg';
export { default as IconElements } from './icon-elements.svg';
export { default as EyeArtworkIconWhite } from './icon-eye.svg';
export { default as IconEyeCard } from './icon-eye-card.svg';
export { default as EyeHideIconWhite } from './icon-eye-hidden-white.svg';
export { default as IconEyeTemplate } from './icon-eye-template.svg';
export { default as FastIcon } from './icon-fast.svg';
export { default as IconFeedback } from './icon-feedback.svg';
export { default as IconFillPrimary } from './icon-fill-primary.svg';
export { default as IconFilter } from './icon-filter.svg';
export { default as IconFooterFixedBlog } from './icon-footer-fixed-blog.svg';
export { default as IconFooterFixedDiscord } from './icon-footer-fixed-Discord.svg';
export { default as IconFooterFixedDocs } from './icon-footer-fixed-docs.svg';
export { default as IconFooterFixedMedium } from './icon-footer-fixed-Medium.svg';
export { default as IconFooterFixedTelegram } from './icon-footer-fixed-Telegram.svg';
export { default as IconFooterFixedX } from './icon-footer-fixed-X.svg';
export { default as IconFooterFixedYoutube } from './icon-footer-fixed-Youtube.svg';
export { default as IconForCreators } from './icon-for-creators.svg';
export { default as IconForVoters } from './icon-for-voters.svg';
export { default as IconGalleryWhite } from './icon-gallery-white.svg';
export { default as IconGenHomepage } from './icon-gen-hompage.svg';
export { default as IconGlobePrimary } from './icon-globe-primary.svg';
export { default as IconGrid } from './icon-grid-one.svg';
export { default as IconGridActive } from './icon-grid-one-active.svg';
export { default as IconGridVertical } from './icon-grid-two.svg';
export { default as IconGridVerticalActive } from './icon-grid-two-active.svg';
export { default as IconHashTag } from './icon-hastag.svg';
export { default as IconKey } from './icon-key.svg';
export { default as IconHeaderAiri } from './icon-header-airi.svg';
export { default as IconHeaderBot } from './icon-header-bot.svg';
export { default as IconHeaderCampaign } from './icon-header-campaign.svg';
export { default as IconHeaderChecknft } from './icon-header-checknft.svg';
export { default as IconHeaderCommunity } from './icon-header-community.svg';
export { default as IconHeaderDown } from './icon-header-down.svg';
export { default as IconHeaderFarming } from './icon-header-farming.svg';
export { default as IconHeaderFeed } from './icon-header-feed.svg';
export { default as IconHeaderHoverAig } from './icon-header-hover-aig.svg';
export { default as IconHeaderHoverBot } from './icon-header-hover-bot.svg';
export { default as IconHeaderHoverCampaign } from './icon-header-hover-campaign.svg';
export { default as IconHeaderHoverChecknft } from './icon-header-hover-checknft.svg';
export { default as IconHeaderHovertFarming } from './icon-header-hover-farming.svg';
export { default as IconHeaderHovertFeed } from './icon-header-hover-feed.svg';
export { default as IconHeaderHovertLeaderboard } from './icon-header-hover-leaderboard.svg';
export { default as IconHeaderHovertMarket } from './icon-header-hover-market.svg';
export { default as IconHeaderHovertPricing } from './icon-header-hover-pricing.svg';
export { default as IconHeaderLeaderboard } from './icon-header-leaderboard.svg';
export { default as IconHeaderMarket } from './icon-header-market.svg';
export { default as IconHeaderMexc } from './icon-header-mexc.svg';
export { default as IconHeaderOraidex } from './icon-header-oraidex.svg';
export { default as IconHeaderPancakeswap } from './icon-header-pancakeswap.svg';
export { default as IconHeaderPricing } from './icon-header-pricing.svg';
export { default as IconHeart } from './icon-heart.svg';
export { default as IconHeartActive } from './icon-heart-active.svg';
export { default as HeartBuyPromptIcon } from './icon-heart-buy-prompt.svg';
export { default as HeartBuyPromptActiveIcon } from './icon-heart-buy-prompt-active.svg';
export { default as IconHistoryWhite } from './icon-history-white.svg';
export { default as IconIdleChip } from './icon-idle-chip.svg';
export { default as IconInfoCardProcess } from './icon-info-card process.svg';
export { default as IconInfoDetail } from './icon-info-detail.svg';
export { default as InfoGenIcon } from './icon-info-gen.svg';
export { default as IconInfoMarketPlace } from './icon-info-marketPlace.svg';
export { default as IconIsLikeTemplate } from './icon-is-like-template.svg';
export { default as IconIslikeTemplate } from './icon-islike-template.svg';
export { default as JoinDiscordIcon } from './icon-join-discord.svg';
export { default as IconKarenn } from './icon-karenn.svg';
export { default as IconKing } from './icon-king.svg';
export { default as IconLayer } from './icon-layer.svg';
export { default as IconLeftArrowTemplate } from './icon-left-arrow-template.svg';
export { default as IconLightningPublish } from './icon-lightning-publish.svg';
export { default as LikeArtworkIcon } from './icon-like-artwork.svg';
export { default as IconLinkArtwork } from './icon-link-artwork.svg';
export { default as IconLinkCollection } from './icon-link-collection.svg';
export { default as IconLinkPrimary } from './icon-link-primary.svg';
export { default as IconLinkWhite } from './icon-link-white.svg';
export { default as IconLockLogin } from './icon-lock-login.svg';
export { default as LoginGoogleIcon } from './icon-login-google.svg';
export { default as LoginOwalletIcon } from './icon-login-owallet.svg';
export { default as LogoImage1 } from './icon-logo-1.svg';
export { default as LogoImage } from './icon-logo-2.svg';
export { default as LogoAiri6060 } from './icon-logo-airi-60-60.svg';
export { default as FooterLogoIcon } from './icon-logo-footer.svg';
export { default as IconLogout } from './icon-logout.svg';
export { default as IconMagic } from './icon-magic.svg';
export { default as IconMagicWandWhite } from './icon-magic-wand-white.svg';
export { default as MediumIcon } from './icon-medium.svg';
export { default as MediumMobileIcon } from './icon-medium-mobile.svg';
export { default as AiModelMenuIcon } from './icon-menu-aimodel.svg';
export { default as MintArtworkIcon } from './icon-mint-artwork.svg';
export { default as IconMore } from './icon-more.svg';
export { default as MusicPlayAudioIcon } from './icon-music-play-audio.svg';
export { default as IconNewChip } from './icon-new-chip.svg';
export { default as NoDataSvg } from './icon-no-data.svg';
export { default as NotificationIcon } from './icon-notification.svg';
export { default as NotificationIconActive } from './icon-notification-active.svg';
export { default as NotificationIconWhite } from './icon-notification-white.svg';
export { default as IconNotlikeTemplate } from './icon-notlike-template.svg';
export { default as IconOptionCardTemplateProfile } from './icon-option-card-template-profile.svg';
export { default as IconOptionSocial } from './icon-option-social.svg';
export { default as IconOptionSocialActive } from './icon-option-social-active.svg';
export { default as IconOptionSocialWhite } from './icon-option-social-white.svg';
export { default as IconPaginationDown } from './icon-pagination-arrow-down.svg';
export { default as IconPaginationNext } from './icon-pagination-next.svg';
export { default as IconPaginationPrev } from './icon-pagination-prev.svg';
export { default as PauseIcon } from './icon-pause.svg';
export { default as PauseAudioBlackIcon } from './icon-pause-audio-black.svg';
export { default as IconPenOrange } from './icon-pen-orange.svg';
export { default as PlayAudioIcon } from './icon-play-audio.svg';
export { default as PlayAudioBlackIcon } from './icon-play-audio-black.svg';
export { default as IconPlus } from './icon-plus.svg';
export { default as IconPlusPrimary } from './icon-plus-primary.svg';
export { default as IconPlusWhite } from './icon-plus-white.svg';
export { default as IconPriceTag } from './icon-price-tag.svg';
export { default as IconProfileClose } from './icon-profile-close.svg';
export { default as IconProfileVerify } from './icon-profile-verify.svg';
export { default as PromptLeaderboard } from './icon-prompt-leaderboard.svg';
export { default as IconPromptTag } from './icon-prompt-tag.svg';
export { default as PushArrowIcon } from './icon-push-arrow.svg';
export { default as Rank1stIcon } from './icon-rank-1st.svg';
export { default as Rank2stIcon } from './icon-rank-2st.svg';
export { default as Rank3stIcon } from './icon-rank-3st.svg';
export { default as IconRedirectGreen } from './icon-redirect-green.svg';
export { default as ReferAccountIcon } from './icon-refer-account.svg';
export { default as IconReloadWhite } from './icon-reload-white.svg';
export { default as IconRemixHover } from './icon-remix-hover.svg';
export { default as IconRemixProcessing } from './icon-remix-processing.svg';
export { default as IconRemixTemplate } from './icon-remix-template.svg';
export { default as IconRemovePost } from './icon-remove-post.svg';
export { default as ResetIcon } from './icon-reset.svg';
export { default as IconRetryArtwork } from './icon-retry-artwork.svg';
export { default as IconRightArrowTemplate } from './icon-right-arrow-template.svg';
export { default as IconRocket } from './icon-rocket.svg';
export { default as IconRunModel } from './icon-run-model.svg';
export { default as IconSearchChip } from './icon-search-chip.svg';
export { default as HeaderSearchIcon } from './icon-search-header.svg';
export { default as HeaderSearchIconWhite } from './icon-search-header-white.svg';
export { default as SelectCollectionGreenIcon } from './icon-select-collection-green.svg';
export { default as SelectModelIcon } from './icon-select-model.svg';
export { default as IconSendMessage } from './icon-send-message.svg';
export { default as IconShare } from './icon-share.svg';
export { default as ShareBuyPromptIcon } from './icon-share-buy-prompt.svg';
export { default as ShareFacebookIcon } from './icon-share-facebook.svg';
export { default as IconShareTemplate } from './icon-share-template.svg';
export { default as ShareTwitterIcon } from './icon-share-twitter.svg';
export { default as IconSlideLeft } from './icon-slide-left.svg';
export { default as IconSlideRight } from './icon-slide-right.svg';
export { default as SocialDiscord } from './icon-social-discord.svg';
export { default as SocialFb } from './icon-social-fb.svg';
export { default as SocialGlobal } from './icon-social-global.svg';
export { default as SocialGmail } from './icon-social-gmail.svg';
export { default as SocialInstagram } from './icon-social-instagram.svg';
export { default as SocialLinkedin } from './icon-social-linkedin.svg';
export { default as SocialMedium } from './icon-social-medium.svg';
export { default as SocialTelegram } from './icon-social-telegram.svg';
export { default as SocialTwitter } from './icon-social-twitter.svg';
export { default as SortMobileIcon } from './icon-sort-mobile.svg';
export { default as IconSparkles } from './icon-sparkles.svg';
export { default as SparklingIcon } from './icon-sparkling.svg';
export { default as IconSuccessGreen } from './icon-success-green.svg';
export { default as IconSupport } from './icon-support.svg';
export { default as IconTagHotMobile } from './icon-tag-hot-mobile.svg';
export { default as TelegramIcon } from './icon-telegram.svg';
export { default as TelegramMobileIcon } from './icon-telegram-mobile.svg';
export { default as IconTelegramPrimaryLinear32 } from './icon-telegram-primary-linear-32*32.svg';
export { default as ToastErrorIcon } from './icon-toast-error.svg';
export { default as ToastSuccessIcon } from './icon-toast-success.svg';
export { default as ToastWarnIcon } from './icon-toast-warn.svg';
export { default as IconTokenAiri } from './icon-token-airi.svg';
export { default as IconTokenOrai } from './icon-token-orai.svg';
export { default as IconTokenUsdt } from './icon-token-usdt.svg';
export { default as IconTopUp } from './icon-top-up.svg';
export { default as IconTop1Leaderboard } from './icon-top1-leaderboard.svg';
export { default as IconTop2Leaderboard } from './icon-top2-leaderboard.svg';
export { default as IconTop3Leaderboard } from './icon-top3-leaderboard.svg';
export { default as IconTotalDownloadModel } from './icon-total-download-model.svg';
export { default as IconTotalLikeModel } from './icon-total-like-model.svg';
export { default as IconTotalUseModel } from './icon-total-use-model.svg';
export { default as TransactionBought } from './icon-transaction-bought.svg';
export { default as TransactionForSale } from './icon-transaction-forsale.svg';
export { default as TransactionMint } from './icon-transaction-mint.svg';
export { default as TransactionTransfer } from './icon-transaction-transfer.svg';
export { default as TransactionWithdraw } from './icon-transaction-withdraw.svg';
export { default as TransferIcon } from './icon-transfer.svg';
export { default as Trash1414Icon } from './icon-trash-14*14.svg';
export { default as IconTrash22x22 } from './icon-trash-22x22.svg';
export { default as IconTrashBlack22x22 } from './icon-trash-black22x22.svg';
export { default as TwitterIcon } from './icon-twitter.svg';
export { default as IconTwitterCampaignCard } from './icon-twitter-campaign-card.svg';
export { default as IconTwitterLinear32 } from './icon-twitter-linear-32*32.svg';
export { default as TwitterMobileIcon } from './icon-twitter-mobile.svg';
export { default as IconTwitterPrimaryLinear32 } from './icon-twitter-primary-linear-32*32.svg';
export { default as UploadFileIcon } from './icon-upload-file.svg';
export { default as UseCaseIcon } from './icon-use-case.svg';
export { default as VaryIcon } from './icon-vary.svg';
export { default as IconVerify16 } from './icon-verify-16px.svg';
export { default as IconViewListWhite } from './icon-viewlist-white.svg';
export { default as IconWarnYellow } from './icon-warn-yellow.svg';
export { default as WarningIcon } from './icon-warning.svg';
export { default as WarningIconCircle } from './icon-warning-circle.svg';
export { default as IconReportSupport } from './icon-warning-support.svg';
export { default as IconWinning } from './icon-winning.svg';
export { default as IconWorkflowEdit } from './icon-workflow-edit.svg';
export { default as YoutubeIcon } from './icon-youtube.svg';
export { default as YoutubeMobileIcon } from './icon-youtube-mobile.svg';
export { default as IconBSC } from './IconBsc.svg';
export { default as IconRocketInPricing } from './iconRocketInPricing.svg';
export { default as IconSize11 } from './iconSize11.svg';
export { default as IconSize23 } from './iconSize23.svg';
export { default as IconSize32 } from './iconSize32.svg';
export { default as IconSize34 } from './iconSize34.svg';
export { default as IconSize43 } from './iconSize43.svg';
export { default as IconSize169 } from './iconSize169.svg';
export { default as IconSize916 } from './iconSize916.svg';
export { default as IconSizeCustom } from './iconSizeCustom.svg';
export { default as LikeGradient } from './like-gradient.svg';
export { default as HomeLogoIcon } from './logo-home-black.svg';
export { default as HomeLogoIconWhite } from './logo-home-white.svg';
export { default as MagicIcon } from './magic-icon.svg';
export { default as MagicWandGradient } from './magic-wand-gradient.svg';
export { default as MostPopularPricing } from './most_popular_pricing.svg';
export { default as OnlyOncePricing } from './only_once_pricing.svg';
export { default as StarMintAndSell } from './star-mintAndSell.svg';
export { default as ToggleSidebar } from './toggle-sidebar.svg';
export { default as ToggleSidebarOpen } from './toggle-sidebar-open.svg';
export { default as Top1Creator } from './top-1-creator.svg';
export { default as Top2Creator } from './top-2-creator.svg';
export { default as Top3Creator } from './top-3-creator.svg';
export { default as Top4Creator } from './top-4-creator.svg';
export { default as UploadGradient } from './upload-gradient.svg';
export { default as VerifyAccount } from './verify_account.svg';
export { default as CreditYellowIcon } from './icon_credit_yellow.svg';
export { default as CreditYellowHeaderIcon } from './icon_header_credit_yellow.svg';
export { default as CreditYellowUserIcon } from './icon_buy_credit_yellow.svg';
export { default as CreditYellowBuyIcon } from './icon_buy_credit_buy.svg';
