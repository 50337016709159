export { default as AIRI } from './airi.png';
export { default as AllowPopup } from './allow-popup.png';
export { default as BgBannerHome } from './bg-banner-home.png';
export { default as BgChatRoom } from './bg-chat-room.png';
export { default as BgFaq } from './bg-faq.png';
export { default as BgSuspenseHomepageItem } from './bg-suspense-homepage.png';
export { default as BgUpdatePlan } from './bg-update-plan.png';
export { default as BnbChain } from './bnb.png';
export { default as ImageCopy } from './copy.png';
export { default as Cursor } from './cursor.png';
export { default as FarmingAiri } from './farming-airi.png';
export { default as FarmingBnb } from './farming-bnb.png';
export { default as FarmingBusd } from './farming-busd.png';
export { default as FarmingKwt } from './farming-kwt.png';
export { default as FarmingMilky } from './farming-milky.png';
export { default as FarmingOrai } from './farming-orai.png';
export { default as FarmingSam } from './farming-sam.png';
export { default as Image404 } from './image-404.png';
export { default as ImageAiModel } from './image-aimodel.png';
export { default as BackgroundUpgradePlan } from './image-bg-upgrade-plan.svg';
export { default as ImageBgUser } from './image-bg-user.svg';
export { default as ImageTempGenItem } from './image-temp-gen-item.png';
export { default as Image1Mission } from './image1_mission.png';
export { default as Image2Mission } from './image2_mission.png';
export { default as Image3Mission } from './image3_mission.png';
export { default as OraiChain } from './orai.png';
export { default as PaperPlane1 } from './PaperPlane1.png';
export { default as PaperPlane2 } from './PaperPlane2.png';
export { default as QuantumLeapAiri } from './quantum_leap-airi.png';
export { default as ThreeDot } from './three-dot.png';
export { default as WalletIcon } from './wallet.png';
export { default as BannerShop } from './banner-shop.png';
export { default as referralImage } from './image_referral.png';
export { default as ImageBgBuyCredit } from './bg-buy-credit.png';
export { default as BgSubcription } from './bg-subcription.png';

